<template>
    <div class="content">
        <div class="icon">
            <img class="forbidden" src="/build/images/not-allowed.svg">
        </div>
        <div class="title">
            {{$t('not-allowed.title')}}
        </div>
        <div class="description narrow">
            {{$t('not-allowed.description')}}
        </div>
        <div class="add-button">
            <router-link class="btn-tbf blue center" :to="{name: 'dashboard' }">
                <div class="text">{{$t('not-found.button')}}</div>
            </router-link>
        </div>
    </div>
</template>

<script>

    export default {
        data(){
            return{
               
            }
        },
        components: {
        },
        methods: {
        }
    }
</script>